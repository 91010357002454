 /*
  * Lightbox gallery
  */

 $('.block-gallery').lightGallery({
     selector: '.post-gallery-item',
     share: true,
     download: true,
     rotate: false,
     showThumbByDefault: true,
     loop: false,
     slideEndAnimatoin: false,
     thumbnail: true,
     allowMediaOverlap: true,
     toggleThumb: true,
     youtubePlayerParams: {
         loadYoutubeThumbnail: true,
         youtubeThumbSize: 'default',
         modestbranding: 1,
         showinfo: 0,
         rel: 0
     }
 });

 /*
  * Owl carousel
  */

 $('.owl-block').owlCarousel({
     margin: 20,
     items: 1,
     loop: true,
     autoplay: true,
     autoplayTimeout: 4500,
     nav: true,
     dots: true,
     navText: ["<i class='icon-angle-left'></i>", "<i class='icon-angle-right'></i>"],
     // navContainer: '#customNav', 
     responsive: {
        0: {
            items: 1
        },
        700: {
            items: 2,
            margin: 0,
        },
        1200: {
            items: 4,
        }
    }
 });


 $('.owl-logos').owlCarousel({
     margin: 20,
     items: 4,
     loop: true,
     autoplay: true,
     autoplayTimeout: 4500,
     nav: true,
     dots: true,
     navText: ["<i class='icon-angle-left'></i>", "<i class='icon-angle-right'></i>"],
     // navContainer: '#customNav', 
     responsive: {
         0: {
             items: 1
         },
         700: {
             items: 2,
             margin: 0,
         },
         1200: {
             items: 4,
         }
     }
 });