document.addEventListener("DOMContentLoaded", function () {
  var unterkunftCheckbox = document.querySelector(".coaching-3-ch input");
  var coachingCheckbox = document.querySelector(".coaching-3-ch-2 input");

  var unterkunftFieldsContainer = document.getElementById("unterkunftFields");
  var coachingFieldsContainer = document.getElementById("coachingFields");

  // Inicijalno sakrij polja ako je prvi checkbox već označen
  if (unterkunftCheckbox.checked) {
    unterkunftFieldsContainer.style.display = "none";
  }

  // Inicijalno sakrij polja ako je drugi checkbox već označen
  if (coachingCheckbox.checked) {
    coachingFieldsContainer.style.display = "none";
  }

  // Dodaj event listenere na promene checkbox-ova
  unterkunftCheckbox.addEventListener("change", function () {
    if (unterkunftCheckbox.checked) {
      unterkunftFieldsContainer.style.display = "none";
    } else {
      unterkunftFieldsContainer.style.display = "block";
    }
  });

  coachingCheckbox.addEventListener("change", function () {
    if (coachingCheckbox.checked) {
      coachingFieldsContainer.style.display = "none";
    } else {
      coachingFieldsContainer.style.display = "block";
    }
  });
});

document.addEventListener("DOMContentLoaded", function () {
  var unterkunftCheckbox = document.querySelector(".coaching-3-ch input");

  unterkunftCheckbox.addEventListener("change", function () {
    var unterkunftstypField = document.getElementById("unterkunftstyp");

    if (unterkunftCheckbox.checked) {
      unterkunftstypField.removeAttribute("required");
    } else {
      unterkunftstypField.setAttribute("required", "required");
    }
  });
});


(function ($) {
  $(function () {


    // //
    // // Bootstrap Carousel Effect Ken Burns
    // // =============================================================================

    // const html = document.querySelector('html');
    // html.setAttribute('data-bs-theme', 'dark');

    // function ready(fn) {
    //   if (document.readyState != 'loading') {
    //     fn()
    //   } else {
    //     document.addEventListener('DOMContentLoaded', fn)
    //   }
    // }

    // ready(() => {
    //   // --- Function to add and remove CSS animation classes
    //   function doAnimations(elems) {
    //     const animEndEv = 'animationend';

    //     elems.forEach((elem) => {
    //       elem.classList.add('animate__animated', 'animate__flipInX');
    //       elem.addEventListener(animEndEv, () => {
    //         elem.classList.remove('animate__animated', 'animate__flipInX');
    //       });
    //     });
    //   }

    //   // --- Variables on page load
    //   const carouselKenBurns = document.querySelector('#carouselKenBurns');
    //   const firstAnimatingElems = Array.from(
    //     carouselKenBurns.querySelector('.carousel-item:first-child')
    //     .querySelectorAll("[data-animation^='animated']")
    //   );

    //   // --- Animate captions in the first slide on page load
    //   doAnimations(firstAnimatingElems);

    //   // --- Other slides to be animated on carousel slide event
    //   carouselKenBurns.addEventListener('slid.bs.carousel', (e) => {
    //     const animatingElems = Array.from(e.relatedTarget.querySelectorAll("[data-animation^='animated']"));
    //     doAnimations(animatingElems);
    //   });
    // })

    AOS.init();



    var date = new Date();

    // Inicijalizacija datepickera za start_date
    $('#form .start_date input, .wpcf7-form .start_date input').datepicker({
      startDate: '0d', // Postavite startDate na danas
      format: 'dd. mm. yyyy',
      autoclose: true // Zatvori datepicker nakon izbora datuma
    }).on('changeDate', function (selected) {
      // Omogući end_date nakon izbora start_date
      $('#form .end_date input, .wpcf7-form .end_date input').prop('disabled', false);

      // Postavi startDate za end_date na izabrani datum start_date + 5 dana
      var startDatePlusFive = new Date(selected.date);
      startDatePlusFive.setDate(startDatePlusFive.getDate() + 5);
      $('#form .end_date input, .wpcf7-form .end_date input').datepicker('setStartDate', startDatePlusFive);
    });


    // Inicijalizacija datepickera za end_date
    $('#form .end_date input, .wpcf7-form .end_date input').datepicker({
      format: 'dd. mm. yyyy', // Postavite format za end_date
      autoclose: true // Zatvori datepicker nakon izbora datuma
    });


    $('.btn-burger').on('click', function (e) {
      e.preventDefault();
      $('.desktop-navigation').toggleClass('open');
      $('body').toggleClass('openNav');
      $(this).toggleClass('open');

      // Toggle icon between menu and close
      // if ($(this).hasClass('open')) {
      //   $(this).find('i').removeClass('icon-menu').addClass('icon-close');
      // } else {
      //   $(this).find('i').removeClass('icon-close').addClass('icon-menu');
      // }
    });
    // Click handler za desktop-navigation
    $('.desktop-navigation').on('click', function (e) {
      e.stopPropagation(); // Ova linija će sprečiti propagaciju klika na body
    });

    // Click handler za body koji zatvara navigaciju
    $('section').on('click', function () {
      if ($('.desktop-navigation').hasClass('open')) {
        $('.desktop-navigation').removeClass('open');
        $('body').removeClass('openNav');
        $('.btn-burger').removeClass('open').find('i').removeClass('icon-close').addClass('icon-menu');
      }
    });


    $('.nav-button').on('click', function (e) {
      e.preventDefault();
      $(this).toggleClass('active');
      $('.navigation-floating').toggleClass('opened');
      $('.overlay-black').addClass('active');

    });

    $('.nav-button.active').on('click', function () {
      $(this).removeClass('active');
      $('.navigation-floating.opened').removeClass('opened');
    });

    /*
     * Navbar fixed 
     */
    // Čekaj da se DOM učita pre nego što počneš sa manipulacijom
    var isScrolled = false; // Da bi pratili stanje skrola

    // Čuvamo originalni logo
    var originalLogo = $('.navbar-brand img').first().attr('src');

    // Navbar fixed
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 1) {
        $('.site-header').addClass('navbar-fixed-top');

        // Ako se skroluje prema dole, postavi novi logo
        if (!isScrolled) {
          isScrolled = true;

          // Prikazi logo_on_scroll i sakrij originalni logo
          $('.navbar-brand img').first().addClass('d-none');
          $('.btn-burger img').first().addClass('d-none');
          $('.navbar-brand img').last().removeClass('d-none');
          $('.btn-burger img').last().removeClass('d-none');
        }
      } else {
        $('.site-header').removeClass('navbar-fixed-top');

        // Ako se skroluje prema gore, vrati originalni logo
        if (isScrolled) {
          isScrolled = false;

          // Prikazi originalni logo i sakrij logo_on_scroll
          $('.navbar-brand img').first().removeClass('d-none');
          $('.btn-burger img').first().removeClass('d-none');
          $('.navbar-brand img').last().addClass('d-none');
          $('.btn-burger img').last().addClass('d-none');
        }
      }
    });

    /*
     * Toggle burger menu on mobile
     */
    $('.menu-btn-burger').on('click', function () {
      $('#nav-icon').toggleClass('open-custom-menu');
      $(".mobile-menu").toggleClass('sliding-open');
    });

    /* 
     * Owl carousel
     */
    $('.owl-room-card').owlCarousel({
      margin: 40,
      items: 3,
      loop: true,
      autoplay: false,
      autoplayTimeout: 4500,
      nav: true,
      dots: false,
      animateOut: 'fadeOut',
      navText: ["<i class='icon-angle-left'></i>", "<i class='icon-angle-right'></i>"],
      // navContainer: '#customNav',
      responsive: {
        0: {
          items: 1,
          nav: false,
          dots: true,
          margin: 0,
        },
        700: {
          items: 2,
          margin: 0,
        },
        1200: {
          items: 3,
        }
      }
    });

    /*
     * Video modal 
     */
    $("body").on("click", "#play-video-modal", function (e) {
      e.preventDefault();
      $("#video-modal").modal("show");
      $("#video-modal iframe")[0].src += "?autoplay=1";
    });

    $("#video-modal").on('hidden.bs.modal', function (e) {
      var videoUrl = $("#video-modal iframe")[0].src;
      videoUrl = videoUrl.replace("?autoplay=1", "");
      $("#video-modal iframe")[0].src = videoUrl;
    });

    /*
     * Youtube lazy loading
     */
    $('#play-video-btn').on('click', function (e) {
      e.preventDefault();
      $('#youtubeModal').modal('show');
    });

    $("#youtubeModal").on('hidden.bs.modal', function (e) {
      $("#youtubeModal iframe").attr("src", $("#youtubeModal iframe").attr("src"));
    });



    /*
     * Scroll up button
     */
    $(window).scroll(function () {
      if ($(this).scrollTop() > 150) {
        $('.scroll-to-top').addClass('show');
      } else {
        $('.scroll-to-top').removeClass('show');
        $('.scroll-to-top').addClass('animated bounceOutDown');
        $('.scroll-to-top').removeClass('animated bounceOutUp');
        $('.scroll-to-top i').css('transform', 'rotateY(0) scale(1)');
      }
    });

    $('.scroll-to-top').on("click", function () {
      $('.scroll-to-top').addClass('animated bounceOutUp');
      $('.scroll-to-top i').css('transform', 'rotateY(180deg) scale(1.2)');
      $("html, body").animate({
        scrollTop: 0
      }, 1000);
      return false;
    });

    /*
     * Lightbox gallery
     */
    $('.gallery-images').lightGallery({
      selector: '.gallery-item',
      share: true,
      download: true,
      rotate: false,
      showThumbByDefault: true,
      loop: false,
      slideEndAnimatoin: false,
      thumbnail: true,
      allowMediaOverlap: true,
      toggleThumb: true,
      youtubePlayerParams: {
        loadYoutubeThumbnail: true,
        youtubeThumbSize: 'default',
        modestbranding: 1,
        showinfo: 0,
        rel: 0
      }
    });



    /*
     * Mmenu light 
     */
    var menu = new MmenuLight(
      document.querySelector('#my-menu'),
      'all'
    );
    if (menu) {
      var navigator = menu.navigation({
        // selectedClass: 'Selected',
        // slidingSubmenus: true,
        theme: 'light',
        title: 'Menü'
      });

      var drawer = menu.offcanvas({
        position: 'right'
      });

      //  Open the menu.
      document.querySelector('#nav-icon')
        .addEventListener('click', evnt => {
          evnt.preventDefault();
          drawer.open();
        });

      $(".mob-menu-close").click(function () {
        drawer.close();
      });
    }

  });
})(jQuery);